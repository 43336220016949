<template>
  <div>
    <Contact />
    <FAQContact is-boutique :is-contact="false" />
  </div>
</template>

<script>
import Contact from "@/components/contact/Contact.vue";
import FAQContact from "@/components/faqcontact/FAQContact.vue";

export default {
  name: "contact",
  components: {
    Contact,
    FAQContact,
  },
};
</script>

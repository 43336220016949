<template>
  <section class="lh-WrapperSingle js-lh-Contact">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link" href="/contact/">Contact</a>
            </li>
          </ul>
          <transition name="fade" mode="out-in">
            <div v-if="isMessageSent" class="lh-Contact-status">
              <h2 class="lh-Form-title">Merci!</h2>
              <p>Votre message a bien été envoyé.</p>
              <a href="/boutique/" class="lh-Button">Retour à la boutique </a>
            </div>
          </transition>
          <transition name="fade" mode="out-in">
            <div v-if="isMessageSentError" class="lh-Contact-status">
              <h2 class="lh-Form-title">Oups...</h2>
              <p>Il y a eu un problème lors de l'envoi.</p>
              <a href="/boutique/" class="lh-Button">Retour à la boutique </a>
            </div>
          </transition>
          <div
            v-if="!isMessageSent && !isMessageSentError"
            class="lh-Contact-form"
          >
            <h1 class="lh-Form-title js-lh-Contact-inputAnimate">
              Envoyez-moi un message&nbsp;!
            </h1>
            <form
              id="lh-ContactForm"
              class="lh-Form js-lh-Form js-lh-ContactForm"
              @submit.prevent="submitForm"
            >
              <p
                class="lh-Form-row js-lh-Contact-inputAnimate"
                v-for="item in items"
                :key="item.name"
                :class="item.isActive ? 'lh-Form-row--focused' : ''"
              >
                <label :for="item.firstName"
                  >{{ item.label }}
                  <span v-if="item.isRequired" class="lh-Form-required"
                    >*</span
                  ></label
                >
                <input
                  v-model="item.value"
                  :type="item.type"
                  :id="item.firstName"
                  @focus="activateInput(item)"
                  @blur="deActivateInput(item)"
                  @input="activateInput(item)"
                  :name="item.firstName"
                  :required="item.isRequired ? true : false"
                />
              </p>
              <p
                class="lh-Form-row js-lh-Contact-inputAnimate"
                :class="message.isActive ? 'lh-Form-row--focused' : ''"
              >
                <label :for="message.name"
                  >{{ message.label }}
                  <span v-if="message.isRequired" class="lh-Form-required"
                    >*</span
                  ></label
                >
                <textarea
                  v-model="message.value"
                  :required="message.isRequired ? true : false"
                  :id="message.name"
                  @focus="activateInput(message)"
                  @blur="deActivateInput(message)"
                  @input="activateInput(message)"
                  :name="message.name"
                ></textarea>
              </p>
              <div
                id="recaptcha-main"
                class="lh-Form-recaptcha g-recaptcha"
                data-sitekey="6Le0AggaAAAAAKq_hg2Tb5IHS4J9cCuXUlWmQC-d"
              ></div>
              <div id="captcha-error">
                <p v-if="!isCaptchaValid" class="lh-Form-error">
                  Veuillez valider le captcha.
                </p>
                <p v-if="isMessageSentCapthaError" class="lh-Form-error">
                  Désolé, un problème est survenu... Réessayez plus tard.
                </p>
              </div>
              <p class="lh-Form-infosRequiered js-lh-Contact-inputAnimate">
                <span class="lh-Form-required">*</span>Champs obligatoires
              </p>
              <button
                class="lh-Button js-lh-Contact-inputAnimate"
                type="submit"
              >
                Envoyer
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--big">
        <div class="lh-Contact-bg">
          <!-- <div class="lh-Contact-bgWater"></div> -->
        </div>
        <!-- <svg>
          <filter
            id="lh-Contact-turbulence"
            x="0"
            y="0"
            width="100%"
            height="100%"
          >
            <feTurbulence
              id="sea-filter"
              numOctaves="3"
              seed="2"
              baseFrequency="0.02 0.05"
            ></feTurbulence>
            <feDisplacementMap
              id="turbulenceScale"
              scale="8"
              in="SourceGraphic"
            ></feDisplacementMap>
            <animate
              xlink:href="#sea-filter"
              attributeName="baseFrequency"
              dur="60s"
              keyTimes="0;0.5;1"
              values="0.02 0.06;0.04 0.08;0.02 0.06"
              repeatCount="indefinite"
            />
          </filter>
        </svg> -->
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
// import Mediaqueries from "@/utlis/mediaqueries";
import { gsap, Power3 } from "gsap";

export default {
  name: "contact",
  data: () => {
    return {
      items: [
        {
          label: "Prénom",
          name: "firstName",
          isRequired: true,
          type: "text",
          isActive: false,
          value: "",
        },
        {
          label: "Nom de famille",
          name: "lastName",
          isRequired: false,
          type: "text",
          isActive: false,
          value: "",
        },
        {
          label: "Email",
          name: "email",
          isRequired: true,
          type: "email",
          isActive: false,
          value: "",
        },
        {
          label: "Téléphone",
          name: "phone",
          isRequired: true,
          type: "tel",
          isActive: false,
          value: "",
        },
      ],
      message: {
        label: "Votre message",
        name: "comment",
        isRequired: true,
        isActive: false,
        value: "",
      },
      isCaptchaValid: true,
      isMessageSent: false,
      isMessageSentError: false,
      isMessageSentCapthaError: false,
    };
  },

  mounted() {
    // this.setTurbulence();
    this.animateInput();
  },

  created() {
    this.$nextTick(() => {
      if (typeof window.grecaptcha !== "undefined") {
        window.grecaptcha.render("recaptcha-main");
      }
    });
  },

  methods: {
    activateInput(item) {
      item.isActive = true;
    },

    deActivateInput(item) {
      if (item.value == "") {
        item.isActive = false;
      }
    },

    // setTurbulence() {
    //   if (Mediaqueries.isMinMedium()) {
    //     document.querySelector("#turbulenceScale").setAttribute("scale", 4);
    //   } else {
    //     document.querySelector("#turbulenceScale").setAttribute("scale", 6);
    //   }
    // },

    animateInput() {
      const inputs = ".js-lh-Contact-inputAnimate";

      const tl = new gsap.timeline();
      tl.staggerFromTo(
        inputs,
        0.6,
        {
          autoAlpha: 0,
          ease: Power3.easeNone,
          y: "+=20",
        },
        {
          autoAlpha: 1,
          ease: Power3.easeNone,
          y: "0",
        },
        0.2
      );
    },

    submitForm() {
      const that = this;
      let firstName = this.items.find((label) => label.name == "firstName");
      firstName = firstName.value;
      let lastName = this.items.find((label) => label.name == "lastName");
      lastName = lastName.value;
      let email = this.items.find((label) => label.name == "email");
      email = email.value;
      let phone = this.items.find((label) => label.name == "phone");
      phone = phone.value;
      let comment = this.message.value;
      if (typeof window.grecaptcha === "undefined") {
        this.isCaptchaValid = false;
        return;
      }
      $.ajax({
        url: "https://lhight.fr/email/form.php",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        type: "POST",
        dataType: "json",
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          comment: comment,
          captcha: window.grecaptcha.getResponse(),
        },
        success: function (data) {
          if (data == "1") {
            that.isCaptchaValid = false;
          } else if (data == "2") {
            that.manageEmailSent();
          } else if (data == "3") {
            that.manageEmailError();
          }
        },
        error: function () {
          that.isMessageSentCapthaError = true;
        },
      });
    },

    manageEmailSent() {
      this.isMessageSent = true;
      this.scrollToTop();
    },

    manageEmailError() {
      this.isMessageSentError = true;
      this.scrollToTop();
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/components/contact/_index.scss";
</style>
